/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

:root {
  /** theme **/
  --color-theme-font: #2c3d7a;
  --color-theme-icon: #5a6cf3;
  --color-background-card: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    #f9f9ff 100%
  );
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.modal-comment {
  &::part(content) {
    border-radius: 20px;
    width: 90%;
    height: 80vh;
  }
  // ion-select::part(icon) {
  //   display: none;
  // }
}
.modal-assessment {
  &::part(content) {
    border-radius: 20px;
    width: 95%;
    height: 90vh;
  }
  // ion-select::part(icon) {
  //   display: none;
  // }
}

.modal-signature{
  &::part(content) {
    border-radius: 20px;
    width: 95%;
    height: 70vh;
    max-width: 700px !important;
    max-height: 600px !important;
    padding-bottom: 1rem;
  }
}
.modal-report{
  &::part(content) {
    border-radius: 20px;
    width: 95%;
    height: 90vh;
    max-width: 900px;
  }
}

.question-cleaning {
  .label-text-wrapper {
    color: #760707 !important;
  }
}

ion-item-divider {
  --inner-padding-end: 0 !important;
}